export default {
  init() {
    // JavaScript to be fired on all pages
    const mobileToggle = document.querySelector('.mobile-toggle');
    const mobileMenu = document.querySelector('.mobile-menu');
  
    $('.nav > .menu-item-has-children').on('mouseenter', function() {
      $('.menu-item-has-children').removeClass('open')
      $(this).addClass('open')
    })

    $('.sub-menu').on('mouseleave', function() {
      $('.menu-item-has-children').removeClass('open')
    })
    
    if(mobileToggle) {
      mobileToggle.addEventListener('click', function() {
        this.classList.toggle('open')
        $('html').toggleClass('no-scroll')
        $('.main-header').toggleClass('open')

        if(mobileMenu) {
          mobileMenu.classList.toggle('open')
        }
      })
    }

    $('.welcome-banner a').on('click', function(e) {
      e.preventDefault()
      $('.welcome-banner').addClass('hide')
      localStorage.setItem('welcomeBanner', 'shown')
    })

    const welcomeBannerStatus = localStorage.getItem('welcomeBanner')
    const welcomeBanner = document.querySelector('.welcome-banner')

    if(welcomeBanner && welcomeBannerStatus) {
      welcomeBanner.classList.add('hide')
    }

    const popUpVideo = document.querySelector('.popup-video-container')
    const popUpVideoCloseBtn = document.querySelector('.popup-video-container svg')
    const player = popUpVideo.querySelector('iframe')
    const videoTriggers = document.querySelectorAll('.video-container[data-video], .video-carousel-slide[data-video]')

    document.addEventListener('keydown', ({key}) => {
      if (key === 'Escape') {
        popUpVideo.close()
        let src = player.src
        player.src = src
      }
    })

    popUpVideo.addEventListener('click', () => {
      popUpVideo.close()
      let src = player.src
      player.src = src
    })

    popUpVideoCloseBtn.addEventListener('click', () => {
      popUpVideo.close()
      let src = player.src
      player.src = src
    })

    videoTriggers.forEach((video) => {
      video.addEventListener('click', () => {
        if(player.src !==  video.dataset.video) {
          player.classList.add('fade-in')
          player.src =  video.dataset.video
        }

        popUpVideo.show()
      })
    })

    let spotlightVideoTrigger = document.querySelector('.spotlight-video-trigger');

    spotlightVideoTrigger.addEventListener('click', () => {
      if(player.src !==  spotlightVideoTrigger.dataset.video) {
        player.classList.add('fade-in')
        player.src =  spotlightVideoTrigger.dataset.video
      }
      popUpVideo.show()
    })

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
